import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { HiArrowLeft } from "react-icons/hi"
import {
  Layout,
  Section,
  SectionParagraph,
  Sections,
  SectionTitle,
  Seo,
} from "../components"

export default function SectionPage({ data }) {
  const { frontmatter, html } = data.markdownRemark
  const { title, cover } = frontmatter
  const img = getImage(cover)

  return (
    <Layout>
      <Seo title={title} />
      <div className="h-banner w-full">
        <Link to="/#inspiration" className="absolute left-10 top-10 z-50">
          <HiArrowLeft className="text-4xl text-white" />
        </Link>
        <GatsbyImage
          image={img}
          alt={`${title} banner`}
          className="h-banner absolute top-0"
        />
        <div className="h-banner absolute top-0 w-full flex items-center justify-center bg-accent bg-opacity-70 px-10 md:px-20">
          <h1 className="text-4xl sm:text-5xl md:text-6xl text-white text-center font-bold font-quicksand py-8 break-words w-full">
            {title}
          </h1>
        </div>
      </div>
      <Sections>
        <Section>
          <SectionTitle>{title}</SectionTitle>
          <SectionParagraph>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </SectionParagraph>
        </Section>
      </Sections>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        cover {
          childImageSharp {
            gatsbyImageData(width: 1920)
          }
        }
      }
      html
    }
  }
`
